import React from 'react';
import Banner from '../../assets/best-party.png';
import Zoom from 'react-medium-image-zoom';
import ButtonPrimary from '../../components/ButtonPrimary';
import { Navbar } from '../navbar/Navbar';

const BestPartyPage = () => {
  return (
    <>
      <Navbar />
      <section>
        {/* Banner Start */}
        <div className='bg-info pt-5'>
          <div className='container-xxl'>
            <div
              className='banner animated slideInLeft'
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: '90%',
              }}
            >
              <div className='container px-0 h-100 d-flex align-items-center'>
                <span className='heading-primary-2 text-white fw-bold'>
                  Best Party
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}
        {/* Main Start */}
        <div className='container py-4 py-md-5'>
          <div>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Best Party was a concept that was supposed to let anyone with or
              without an account post stuff on the site, in order to share the
              ideas for creating the best party. It was supposed to be a short
              term site till the party happened.
            </p>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Tools: Figma, Adobe Illustrator
            </p>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Strategy: This project was mostly done with straight to design
              approach rather than formal research for UX as client pretty much
              knew what functions they wanted to have on their site.
            </p>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6 col-lg-5'>
              <div className='d-flex flex-column'>
                <div>
                  <div className='heading-primary-3 text-info mb-2'>
                    Homepage
                  </div>
                  <p className='heading-secondary-2 text-secondary mb-4'>
                    You can see, like and comment on trending posts and create
                    posts of your own without even signing In.
                  </p>
                </div>
                <Zoom>
                  <img
                    className='h-100 w-100 d-block'
                    src='./images/best-party/image-1.png'
                    alt='...'
                  />
                </Zoom>
              </div>
            </div>
            <div className='col-12 col-md-5 col-lg-4 mx-auto'>
              <div className='d-flex flex-column padding-top-1 col-md-9 px-0'>
                <div>
                  <div className='heading-primary-3 text-info mb-2'>Login</div>
                  <p className='heading-secondary-2 text-secondary mb-4'>
                    A form to Login via email, Google and Facebook.
                  </p>
                </div>
                <Zoom>
                  <img
                    className='h-100 w-100 d-block'
                    src='./images/best-party/image-2.png'
                    alt='...'
                  />
                </Zoom>
              </div>
              {/* === */}
              <div className='d-flex flex-column padding-top-1'>
                <div>
                  <div className='heading-primary-3 text-info mb-2'>
                    Register your self
                  </div>
                  <p className='heading-secondary-2 text-secondary mb-4'>
                    Add Picture, name, email and password.
                  </p>
                </div>
                <Zoom>
                  <img
                    className='h-100 w-100 d-block'
                    src='./images/best-party/image-3.png'
                    alt='...'
                  />
                </Zoom>
              </div>
            </div>
          </div>
          {/* Button Start  */}
          <div className='text-center pt-5 mt-5'>
            <ButtonPrimary className='btn-info' />
          </div>
          {/* Button End */}
        </div>
        {/* Main End */}
      </section>
    </>
  );
};

export default BestPartyPage;
