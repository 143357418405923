import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Banner from '../../assets/advyant.png';
import Zoom from 'react-medium-image-zoom';
import ButtonPrimary from '../../components/ButtonPrimary';
import { Navbar } from '../navbar/Navbar';

const AdvyantPage = () => {
  return (
    <>
      <Navbar />
      <section>
        {/* Banner Start */}
        <div className='bg-danger pt-5'>
          <div className='container-xxl'>
            <div
              className='banner animated slideInLeft'
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: '100%',
              }}
            >
              <div className='container px-0 h-100 d-flex align-items-center'>
                <span className='heading-primary-2 text-white fw-bold'>
                  Advyant
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}
        {/* Main Start */}
        <div className='container py-4 py-md-5'>
          <div>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Advyant is a food and service booking and feedback providing site.
              I started with stake holders meeting and drafting quick wireframes
              on paper and later converted to UI. Some of my favourite shots are
              attached below.
            </p>
          </div>
          {/* Row Start */}
          <div className='row justify-content-center'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-danger mb-2'>
                      Home Page
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      A welcoming homepage that shows different things at
                      different locations and enable users to get info and see
                      ratings of different services.
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/advyant/image-1.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mx-auto'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column padding-top-1'>
                  <div>
                    <div className='heading-primary-3 text-danger mb-2'>
                      Search Results
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Search results for a service and get various similar
                      results with added filters option to get the perfect thing
                      you are looking for.
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/advyant/image-2.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* Row End */}

          {/* Row Start */}
          <div className='row justify-content-center mt-5 pt-md-5'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-danger mb-2'>
                      Seller Profile
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Overview of seller Profile, where users can check reviews
                      and book services and if they have already purchased a
                      service, they can write a review.
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/advyant/image-3.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-5 col-lg-4 mx-auto mt-4 mt-md-0'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-danger mb-2'>
                      Bookings
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      User's can check bookings, cancel them and check clients
                      details
                    </p>
                  </div>
                  <div>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/advyant/image-4.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* Row End */}

          {/* Row Start */}
          <div className='row justify-content-center mt-4'>
            <div className='col-12 col-md-5 col-lg-4'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-danger mb-2'>
                      Login
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Minimal Login and sign up
                    </p>
                  </div>
                  <div>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/advyant/image-5.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mx-auto '></div>
          </div>
          {/* Row End */}

          {/* Button Start  */}
          <div className='text-center pt-5 mt-5'>
            <ButtonPrimary className='btn-danger' />
          </div>
          {/* Button End  */}
        </div>
        {/* Main End */}
      </section>
    </>
  );
};

export default AdvyantPage;
