import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdvyantPage from './views/advyant/AdvyantPage';
import BestPartyPage from './views/best-party/BestPartyPage';
import BloxwinPage from './views/bloxwin/BloxwinPage';
import Builder from './views/builder/Builder';
import HomePage from './views/home/HomePage';
import SmartWeightPage from './views/smart-weight/SmartWeightPage';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/best-party' component={BestPartyPage} />
      <Route exact path='/builder' component={Builder} />
      <Route exact path='/smart-weight' component={SmartWeightPage} />
      <Route exact path='/advyantt' component={AdvyantPage} />
      <Route exact path='/blox-win' component={BloxwinPage} />
    </Switch>
  );
};

export default Routes;
