import React from 'react';
import { Container } from 'react-bootstrap';

const SectionTwo = () => {
  return (
    <Container>
      <div className='py-5'>
        <div className='text-center text-primary heading-primary-2 mb-5'>
          Skills & Tools
        </div>
        <div className='px-0 col-md-10 col-lg-7 mx-auto'>
          <img
            style={{ transform: 'scale(1.4)' }}
            className='h-100 w-100 d-block'
            src='./images/brands.png'
            alt='brands'
          />
        </div>
      </div>
    </Container>
  );
};

export default SectionTwo;
