import Routes from './Routes.js';
import ScrollToTop from './components/ScrollToTop';
import Footer from './views/footer/Footer.jsx';

function App() {
  return (
    <main>
      <ScrollToTop />

      <Routes />
      <Footer />
    </main>
  );
}

export default App;
