import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Zoom from 'react-medium-image-zoom';
import Banner from '../../assets/builder.png';
import ButtonPrimary from '../../components/ButtonPrimary';
import { Navbar } from '../navbar/Navbar';

const Builder = () => {
  return (
    <>
      <Navbar />
      <section className='bg-color-1'>
        {/* Banner Start */}
        <div className='bg-black pt-5'>
          <div className='container-xxl'>
            <div
              className='banner animated slideInLeft'
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: '100%',
              }}
            >
              <div className='container px-0 h-100 d-flex align-items-center'>
                <span className='heading-primary-2 text-warning fw-bold'>
                  Builder
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}
        {/* Main Start */}
        <div className='container py-4 py-md-5'>
          <div>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Builder is the app that let's you plan your entire construction
              plan online with you having to worry about any scattered plans.
              With Builder you can manage your suppliers, contractors, Budget,
              Timeline, Inspiration boards all at one place.
            </p>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Tools: Adobe Illustrator
            </p>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Strategy: This project started with multiple stakeholders
              meetings, then wireframes were created and later a colour pallete
              was selected to symbolize performance and progress and after
              continuous feedback from project manager the site was presented to
              client.
            </p>
          </div>
          {/* Row Start */}
          <div className='row justify-content-center'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-warning mb-2'>
                      Dashboard
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      An overview of the whole app, here you can see the current
                      projects and their progress.
                    </p>
                  </div>
                  <div>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/builder/image-1.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mx-auto'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column padding-top-1'>
                  <div>
                    <div className='heading-primary-3 text-warning mb-2'>
                      Inspiration Boards
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      A collection of your favourite boards to keep you
                      motivated throughout the project.
                    </p>
                  </div>
                  <div className='col-md-10 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/builder/image-2.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* Row End */}

          {/* Row Start */}
          <div className='row justify-content-center mt-4 mt-md-0'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-warning mb-2'>
                      Budgeter
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      An overview and per item view of project Budget and cost
                      with charts.
                    </p>
                  </div>
                  <Zoom>
                    <img
                      className='h-100 w-100 d-block'
                      src='./images/builder/image-3.png'
                      alt='...'
                    />
                  </Zoom>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mx-auto'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column padding-top-1'>
                  <div>
                    <div className='heading-primary-3 text-warning mb-2'>
                      Contractors
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      A list of contractors with their ratings, contact details
                      and their scheduled dates.
                    </p>
                  </div>
                  <Zoom>
                    <img
                      className='h-100 w-100 d-block'
                      src='./images/builder/image-4.png'
                      alt='...'
                    />
                  </Zoom>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* Row End */}

          {/* Row Start */}
          <div className='row justify-content-center mt-4 mt-md-0'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-warning mb-2'>
                      Project Checklist
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      A list of steps and time left till completion for your
                      project
                    </p>
                  </div>
                  <Zoom>
                    <img
                      className='h-100 w-100 d-block'
                      src='./images/builder/image-5.png'
                      alt='...'
                    />
                  </Zoom>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mx-auto'></div>
          </div>
          {/* Row End */}

          {/* Button Start  */}

          <div className='text-center pt-5 mt-5'>
            <ButtonPrimary className='btn-warning' />
          </div>

          {/* Button End  */}
        </div>
        {/* Main End */}
      </section>
    </>
  );
};

export default Builder;
