import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Zoom from 'react-medium-image-zoom';
import Banner from '../../assets/bloxwin.png';
import ButtonPrimary from '../../components/ButtonPrimary';
import { Navbar } from '../navbar/Navbar';

const BloxwinPage = () => {
  return (
    <>
      <Navbar />
      <section>
        {/* Banner Start */}
        <div className='bg-success pt-5'>
          <div className='container-xxl'>
            <div
              className='banner animated slideInLeft'
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: '100%',
              }}
            >
              <div className='container px-0 h-100 d-flex align-items-center'>
                <span className='heading-primary-2 text-white fw-bold'>
                  Bloxwin
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}
        {/* Main Start */}
        <div className='container py-4 py-md-5'>
          <div>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Bloxwin is an online Gaming site, where users can users can use
              Roblox currency Robux to play different games and win more Rbux.
            </p>
          </div>
          {/* Row Start */}
          <div className='row justify-content-center'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-success mb-2'>
                      Dashboard
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Dashboard where you can see all that has been offered by
                      Bloxwin as well as keep an eye on your wallet and Profile.
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/bloxwin/image-1.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mx-auto'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column padding-top-1'>
                  <div>
                    <div className='heading-primary-3 text-success mb-2'>
                      Claim Rewards
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      A pop-up for claiming different rewards after you have
                      achieved certain levels.
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/bloxwin/image-2.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* Row End */}

          {/* Row Start */}
          <div className='row justify-content-center mt-5 pt-md-5'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-success mb-2'>
                      Login
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Login form with Register link and ability to login with
                      Google.
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/bloxwin/image-3.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-5 col-lg-4 mx-auto mt-4 mt-md-0'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column padding-top-1'>
                  <div>
                    <div className='heading-primary-3 text-success mb-2'>
                      Claim Rewards
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      A pop-up for claiming different rewards after you have
                      achieved certain levels.
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/bloxwin/image-4.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* Row End */}

          {/* Row Start */}
          <div className='row justify-content-center mt-5'>
            <div className='col-12 col-md-5 col-lg-4'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-success mb-2'>
                      Badges
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Collectables for completing certain number of offers
                    </p>
                  </div>
                  <div className='col-md-8 px-0'>
                    <Zoom>
                      <img
                        className='h-100 w-100 d-block'
                        src='./images/bloxwin/image-5.png'
                        alt='...'
                      />
                    </Zoom>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-12 col-md-6 col-lg-5 mx-auto '></div>
          </div>
          {/* Row End */}

          {/* Button Start  */}
          <div className='text-center pt-5 mt-5'>
            <ButtonPrimary className='btn-success' />
          </div>
          {/* Button End  */}
        </div>
        {/* Main End */}
      </section>
    </>
  );
};

export default BloxwinPage;
