import React from 'react';
import { Container } from 'react-bootstrap';

const SectionOne = () => {
  return (
    <Container fluid='xxl'>
      <div className='px-sm-5 px-xxl-0 py-5'>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-6'>
            <div className='text-center'>
              <div className='text-primary heading-primary'>Shabab Altaf</div>
              <div className='text-secondary heading-secondary'>
                Full Stack UX UI Designer
              </div>
            </div>
          </div>
          <div className='col-10 col-md-5 order-first order-lg-last mb-4 mb-md-0 mx-auto'>
            <div>
              <img
                className='h-100 w-100 d-block bounce-top'
                src='./images/pattern-1.svg'
                alt='pattern'
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SectionOne;
