import React from 'react';
import { Navbar } from '../navbar/Navbar';
import SectionOne from './sections/SectionOne';
import SectionThree from './sections/SectionThree';
import SectionTwo from './sections/SectionTwo';

const HomePage = () => {
  return (
    <>
      <Navbar />
      <section>
        <div className='pt-5 mt-5' />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
      </section>
    </>
  );
};

export default HomePage;
