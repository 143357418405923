import React from 'react';
import Banner from '../../assets/smart-weight.png';
import Zoom from 'react-medium-image-zoom';
import ButtonPrimary from '../../components/ButtonPrimary';
import ScrollAnimation from 'react-animate-on-scroll';
import { Navbar } from '../navbar/Navbar';

const SmartWeightPage = () => {
  return (
    <>
      <Navbar />
      <section>
        {/* Banner Start */}
        <div className='bg-light pt-5'>
          <div className='container-xxl'>
            <div
              className='banner animated slideInLeft'
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundSize: '80%',
              }}
            >
              <div className='container px-0 h-100 d-flex align-items-center'>
                <span className='heading-primary-2 text-white fw-bold'>
                  SmartWeight
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}
        {/* Main Start */}
        <div className='container py-4 py-md-5'>
          <div>
            <p className='heading-secondary-2 text-secondary mb-4'>
              SmartWeight let's you manage your weight by simply tracking your
              daily points with no need to go for extreme crash diets.
            </p>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Tools: Adobe XD, Adobe Illustrator
            </p>
            <p className='heading-secondary-2 text-secondary mb-4'>
              Strategy: This project started with multiple stakeholders
              meetings, then wireframes were created and later a colour palette
              was selected which represented trust and professionalism. To
              improve the usability as we were targeting users with management
              needs we used different apps to keep the ux as familiar as
              possible for users.
            </p>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6 col-lg-5'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column'>
                  <div>
                    <div className='heading-primary-3 text-light mb-2'>
                      Home Screen
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Here you can see your daily points, track your food and
                      see which items they are composed of and track your
                      activity and hydration.
                    </p>
                  </div>
                  <Zoom>
                    <img
                      className='h-100 w-100 d-block'
                      src='./images/smart-weight/image-1.png'
                      alt='...'
                    />
                  </Zoom>
                </div>
              </ScrollAnimation>
              {/* === */}
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column padding-top-1'>
                  <div>
                    <div className='heading-primary-3 text-light mb-2'>
                      Questionnaire (sign up)
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Multiple options to know user's personal preference
                      instead of single page boring form.
                    </p>
                  </div>
                  <Zoom>
                    <img
                      className='h-100 w-100 d-block'
                      src='./images/smart-weight/image-4.svg'
                      alt='...'
                    />
                  </Zoom>
                </div>
              </ScrollAnimation>
              {/* === */}
            </div>
            <div className='col-12 col-md-6 col-lg-6 mx-auto align-self-center'>
              <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut'>
                <div className='d-flex flex-column padding-top-1'>
                  <div>
                    <div className='heading-primary-3 text-light mb-2'>
                      Restaurant Module
                    </div>
                    <p className='heading-secondary-2 text-secondary mb-4'>
                      Here you can see different restaurants and their menu's as
                      well as points for the associated foods.
                    </p>
                  </div>

                  <div className='row row-cols-2 mx-0'>
                    <div className='align-self-baseline px-0'>
                      <Zoom>
                        <img
                          className='h-100 w-100 d-block'
                          src='./images/smart-weight/image-2.png'
                          alt='...'
                        />
                      </Zoom>
                    </div>
                    <div className='mt-4 pt-2 px-0'>
                      <Zoom>
                        <img
                          className='h-100 w-100 d-block'
                          src='./images/smart-weight/image-3.png'
                          alt='...'
                        />
                      </Zoom>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* Button Start  */}

          <div className='text-center pt-5 mt-5'>
            <ButtonPrimary className='btn-light' />
          </div>

          {/* Button End */}
        </div>
        {/* Main End */}
      </section>
    </>
  );
};

export default SmartWeightPage;
