import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonPrimary from '../../../components/ButtonPrimary';

const SectionThree = () => {
  return (
    <Container fluid='xxl'>
      <div className='py-5'>
        <div className='text-center text-primary heading-primary-2 mb-1 mb-md-5'>
          My Process
        </div>
        {/* Row Start */}
        <Row className='height-1'>
          <Col md={6} lg={4} className='mx-auto'>
            <div className='text-center text-md-start'>
              <div className='text-primary heading-primary-3 mb-2'>Problem</div>
              <div className='text-secondary heading-secondary-2'>
                The Pain point that we are going to provide relief for.
              </div>
            </div>
          </Col>
          <Col
            xs={10}
            md={4}
            className='order-first order-md-last mb-4 mb-md-0 mx-auto'
          >
            <div className='position-relative '>
              <img
                className='w-100 pattern-pos-1 bounce-top'
                src='./images/pattern-2.svg'
                alt='pattern'
              />
            </div>
          </Col>
        </Row>
        {/* Row End */}
        {/* Row Start */}
        <Row className='mt-4'>
          <Col xs={10} md={4} className='mb-4 mb-md-0 mx-auto'>
            <div className='position-relative'>
              <img
                className='w-100 pattern-pos-2 bounce-top'
                src='./images/pattern-3.svg'
                alt='pattern'
              />
            </div>
          </Col>
          <Col md={6} lg={4} className='mx-auto'>
            <div className='text-center text-md-start'>
              <div className='text-primary heading-primary-3 mb-2'>
                Possible Solutions
              </div>
              <div className='text-secondary heading-secondary-2'>
                Drafting a couple of ways we can solve the problem, these can be
                paper wireframes or an example of pre-existing solutions
              </div>
            </div>
          </Col>
        </Row>
        {/* Row End */}
        {/* Row Start */}
        <Row className='mt-5 mt-xl-0'>
          <Col md={6} lg={6} xl={4} className='margin-left-1'>
            <div className='text-center text-md-start'>
              <div className='text-primary heading-primary-3 mb-2'>
                Test Possible Solutions
              </div>
              <div className='text-secondary heading-secondary-2'>
                Check the solutions compatibility with user personas and create
                rapid prototypes to be tested. After selecting a particular flow
                adding further components to streamline the process.
              </div>
            </div>
          </Col>
        </Row>
        {/* Row End */}
        {/* Row Start */}
        <Row className='mt-4'>
          <Col xs={10} md={4} className='d-none d-md-block'></Col>
          <Col md={6} lg={4} className='ms-auto position-relative'>
            <img
              className='w-100 pattern-pos-3 bounce-top'
              src='./images/pattern-4.svg'
              alt='pattern'
            />
            <div className='text-center text-md-start z-10' style={{ backgroundColor: '#ffffffb3'}}>
              <div className='text-primary heading-primary-3 mb-2'>
                Polish feasible Solution
              </div>
              <div className='text-secondary heading-secondary-2'>
                Designing User interface using brand guidelines and polished
                wireframes.
              </div>
            </div>
          </Col>
        </Row>
        {/* Row End */}
      </div>
      <div className='text-center padding-1'>
        <ButtonPrimary />
      </div>
    </Container>
  );
};

export default SectionThree;
