import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { ReactComponent as Email } from '../../assets/email.svg';

export const Navbar = () => {
  const location = useLocation();

  const isHome = location.pathname === '/' ? true : false;

  const getBackground = (location) => {
    const { pathname } = location;

    if (pathname === '/') {
      return 'bg-white';
    } else if (pathname === '/advyantt') {
      return 'bg-danger';
    } else if (pathname === '/builder') {
      return 'bg-black';
    } else if (pathname === '/smart-weight') {
      return 'bg-light';
    } else if (pathname === '/blox-win') {
      return 'bg-success';
    } else {
      return;
    }
  };

  const background = getBackground(location);

  const getActiveBtn = (location) => {
    const { pathname } = location;

    if (pathname === '/') {
      return 'main-item-0';
    } else if (pathname === '/advyantt') {
      return 'main-item-1';
    } else if (pathname === '/builder') {
      return 'main-item-2';
    } else if (pathname === '/smart-weight') {
      return 'main-item-3';
    } else if (pathname === '/blox-win') {
      return 'main-item-4';
    } else {
      return;
    }
  };

  const activeBtn = getActiveBtn(location);

  return (
    <div className={`fixed-top  py-3  ${background}`}>
      <Container>
        <div className='d-flex align-items-center'>
          <div>
            <Link to='/'>
              <Logo
                className={`icon-hover ${
                  isHome ? 'text-primary' : 'text-white'
                }`}
              />
            </Link>
          </div>
          <div className='ms-auto d-flex align-items-center'>
            <ol className='main-menu mb-0 me-3 me-md-5'>
              <li className={`main-item ${activeBtn}`}>
                <span className='widgets'></span>
                <ol className='sub-menu'>
                  {/* <NavLink
                    exact
                    to='/best-party'
                    className={`sub-item item-left ${activeBtn}`}
                  >
                    <span className='link-name-1'></span>
                  </NavLink> */}
                  <NavLink
                    exact
                    to='/builder'
                    className={`sub-item item-left ${activeBtn}`}
                  >
                    <span className='link-name-2'></span>
                  </NavLink>
                  <NavLink
                    exact
                    to='/smart-weight'
                    className={`sub-item item-left ${activeBtn}`}
                  >
                    <span className='link-name-3'></span>
                  </NavLink>
                  <NavLink
                    exact
                    to='/advyantt'
                    className={`sub-item item-left ${activeBtn}`}
                  >
                    <span className='link-name-4'></span>
                  </NavLink>
                  <NavLink
                    exact
                    to='/blox-win'
                    className={`sub-item item-left ${activeBtn}`}
                  >
                    <span className='link-name-5'></span>
                  </NavLink>
                </ol>
              </li>
            </ol>
            <a href='mailto:shabablaltaf1994@gmail.com' className='me-2'>
              <Email
                className={`icon-hover ${
                  isHome ? 'text-primary' : 'text-white'
                }`}
              />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};
