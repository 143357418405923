import React from 'react';

const Footer = () => {
  return (
    <footer className='py-5'>
      <div className='d-flex align-items-center justify-content-center position-relative py-5'>
        <a
          className='me-5'
          href='https://dribbble.com/shababaltaf'
          target='_blank'
          rel='noreferrer'
        >
          <img
            height='40px'
            className='d-block icon-hover'
            src='./images/icon-1.png'
            alt='icon'
          />
        </a>
        <a
          className='ms-5'
          href='https://www.linkedin.com/in/shababaltaf'
          target='_blank'
          rel='noreferrer'
        >
          <img
            height='40px'
            className='d-block icon-hover bg-white rounded'
            src='./images/icon-2.png'
            alt='icon'
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
